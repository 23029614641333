import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, EuiFlexItem, useToast } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStartWorkflowLazyQuery } from '@app/graphql/queries/workflow/__generated__/startWorkflow.generated';
import { TaskType } from '@app/graphql/types';
import { useWorkflowContext } from '@app/platform/Workflow/context/WorkflowContext';
import { usePrecheckContext } from '../../context/PrecheckContext';

export const WorkflowButtons = () => {
  const { params, recommendations } = usePrecheckContext();
  const { selectedOrganization } = useUserSession();
  const history = useHistory();
  // use this instead of the workflowStartedLoading state since it's a polling query and not always in loading state
  const [isWorkflowStartedLoading, setIsWorkflowStartedLoading] = useState(false);
  // FIX ME
  // @ts-ignore
  const { workflowStarted, workflowStartedLoading, startPolling, stopPolling } =
    useWorkflowContext();
  const toast = useToast();
  // Because there's a delay in between when workflow is started and when the tasks are created we
  // are creating a local state to track if the workflow has been started. So that we can show the
  // "View Tasks" button before the inbox returns more than 0 tasks.

  const [startWorkflowQuery, { loading: startWorkflowLoading }] = useStartWorkflowLazyQuery({
    onError: (error) => {
      console.error(error);
      toast({ title: 'Could not start workflow', type: 'danger' });
    },
    variables: {
      input: {
        enabledPriorityBuckets: recommendations.data.buckets.map((bucket) => bucket.name),
        orgName: selectedOrganization?.name,
        streamSlug: params.streamId,
        taskType: TaskType.PrecheckRecommendations,
      },
    },
  });

  useEffect(() => {
    if (workflowStarted) {
      setIsWorkflowStartedLoading(false);
      stopPolling();
    }
  }, [workflowStarted]);

  const createTasksHandler = () => {
    startWorkflowQuery();
    setIsWorkflowStartedLoading(true);
    startPolling(3000);
  };

  const loading = workflowStartedLoading || startWorkflowLoading || isWorkflowStartedLoading;

  // when a workflow has started (driven by the backend), this means that the recommendations in the
  // inbox will be generated
  if (workflowStarted) {
    return (
      <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
        <Button
          label="View Tasks"
          onClick={() => {
            history.push({
              pathname: encodeUrl`/organizations/${selectedOrganization.name}/inbox`,
            });
          }}
        />
      </EuiFlexItem>
    );
  }

  return (
    <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
      <Button
        loading={loading}
        disabled={loading}
        label={'Create Tasks'}
        onClick={createTasksHandler}
      />
    </EuiFlexItem>
  );
};
