import React, { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  Tabs,
  useToast,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useSubmitTasksMutation } from '@app/graphql/precheck/mutations/submitTasks/__generated__/submitTasks.generated';
import { RecommendationType } from '@app/graphql/precheck/precheck.types';
import { useGetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getSingleTask.generated';
import { PropertyDataInput } from '@app/graphql/types';
import { useTracker } from '@app/hooks/useTracker';
import { usePrecheckContext } from '@app/precheck/context/PrecheckContext';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { MissingAttributes } from '../MissingAttributes/MissingAttributes';
import { useTaskContext } from '../V2/context/TaskContext';
import { IFormattedtask } from '../V2/context/types';
import { formatTasksAndOptions } from '../V2/context/utils';
import { FlyoutHeader } from '../V2/FlyoutHeader';
import { ValuationOutlier } from '../ValuationOutlier/ValuationOutlier';

interface Props {
  dismissHandler: () => void;
  task: IFormattedtask;
  refetchTask: () => void;
  closeTaskFlyout: () => void;
}

const StyledFlyout = styled(EuiFlyout)`
  inline-size: 45vw !important;
`;

const StyledDiv = styled.div`
  padding: 24px;
`;

const StyledEuiFlyoutFooter = styled(EuiFlyoutFooter)`
  padding: 16px 24px !important;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

enum TAB_IDS {
  MISSING_ATTRIBUTES_TAB = 'missing-attributes-tab',
  VALUATION_OUTLIERS_TAB_ID = 'valuation-outliers-tab',
}

export const TaskFlyoutWrapper = ({ dismissHandler }) => {
  const { closeFlyout } = useContext(FlyoutContext);
  const client = usePrecheckClient();
  const { selectedTaskId, setSelectedTaskId } = useTaskContext();
  const { selectedOrganization } = useUserSession();

  const {
    data,
    loading,
    error,
    refetch: refetchTask,
  } = useGetTasksQuery({
    client,
    variables: {
      input: {
        orgName: selectedOrganization.name,
        taskIDs: [selectedTaskId],
      },
    },
  });

  const { transformedTasks } = formatTasksAndOptions(data?.getTasks.tasks);
  const task = transformedTasks[0];

  const closeTaskFlyout = () => {
    setSelectedTaskId(null);
    closeFlyout();
  };

  return (
    <StyledFlyout paddingSize="none" onClose={closeTaskFlyout}>
      {loading || error ? (
        <LoadingSpinnerV2 />
      ) : (
        <TaskFlyoutContent
          closeTaskFlyout={closeTaskFlyout}
          task={task as IFormattedtask}
          refetchTask={refetchTask}
          dismissHandler={dismissHandler}
        />
      )}
    </StyledFlyout>
  );
};

export const TaskFlyoutContent: FC<Props> = ({
  task,
  dismissHandler,
  refetchTask,
  closeTaskFlyout,
}) => {
  const client = usePrecheckClient();
  const { refetch, setSelectedTaskId } = useTaskContext();
  const tracker = useTracker();
  const { selectedOrganization } = useUserSession();
  const toast = useToast();

  const { stream } = usePrecheckContext();
  // eslint-disable-next-line
  const [_, setSelectedTab] = useState<string>(TAB_IDS.MISSING_ATTRIBUTES_TAB);

  const containsValuationOutlier = task?.attributes.some(
    (attribute) => attribute.recommendationType === RecommendationType.ValuationOutlier,
  );

  const containsMissingAttrs = task?.attributes.some(
    (attribute) => attribute.recommendationType === RecommendationType.MissingAttribute,
  );

  const defaultValues = task?.attributes
    .filter((attribute) => attribute.recommendationType === RecommendationType.ValuationOutlier)
    .reduce((acc, attribute) => {
      const value = task.property?.[attribute.name];
      //@ts-ignore
      acc[attribute.attributeMetadata.name] = value;
      return acc;
    }, {});

  const formMethods = useForm({
    // if this doesn't work, need to do a useEffect, form reset
    defaultValues,

    mode: 'onSubmit',
  });

  const [submitTask, { loading: submitLoading }] = useSubmitTasksMutation({
    client,
    onCompleted: async () => {
      setSelectedTaskId(null);
      closeTaskFlyout();
      await refetch();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const completeTask = (propertyData?: PropertyDataInput) => {
    tracker.track('Pre-Check: Task Completed', {
      taskId: task.id,
    });
    submitTask({
      variables: {
        input: {
          orgName: selectedOrganization.name,
          tasksInput: [
            {
              propertyArchipelagoID: task.propertyId,
              propertyData: propertyData,
              taskID: task.id,
            },
          ],
        },
      },
    });
  };

  const submitHandler = () => {
    const propertyInput = {};
    const vals = formMethods.getValues();
    for (const val in vals) {
      if (vals[val] !== '' && vals[val] !== undefined && vals[val] !== task.property?.[val]) {
        propertyInput[val] =
          ['buildingValue', 'floorArea'].includes(val) && !vals[val] ? 0 : vals[val];
      }
    }
    completeTask(propertyInput);
  };

  const tabs = [];

  if (containsMissingAttrs) {
    tabs.push({
      content: (
        <StyledDiv>
          <MissingAttributes task={task} refetch={refetchTask} />
        </StyledDiv>
      ),
      id: TAB_IDS.MISSING_ATTRIBUTES_TAB,
      label: 'Missing Attributes',
      onClick: () => {
        setSelectedTab(TAB_IDS.MISSING_ATTRIBUTES_TAB);
      },
      style: {
        marginLeft: '24px',
      },
    });
  }

  if (containsValuationOutlier) {
    tabs.push({
      content: (
        <StyledDiv>
          <ValuationOutlier
            defaultValues={defaultValues}
            formValues={formMethods.getValues()}
            task={task}
            refetch={refetchTask}
            setValue={formMethods.setValue}
          />
        </StyledDiv>
      ),
      id: TAB_IDS.VALUATION_OUTLIERS_TAB_ID,
      label: 'Valuation Outlier',
      onClick: () => {
        setSelectedTab(TAB_IDS.VALUATION_OUTLIERS_TAB_ID);
      },
    });
  }

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={formMethods.handleSubmit(submitHandler)}>
        <FlyoutHeader hasBorder={tabs?.length === 1} task={task} streamSlug={stream?.slug} />
        <EuiFlyoutBody>
          {tabs?.length > 1 && <Tabs initialTabId={tabs[0]?.id} tabs={tabs} />}
          {tabs?.length === 1 && tabs[0]?.content}
        </EuiFlyoutBody>
        <StyledEuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              {dismissHandler && (
                <ButtonEmpty
                  loading={submitLoading}
                  disabled={submitLoading}
                  onClick={dismissHandler}
                  color="danger"
                  iconName="trash"
                  label="Dismiss Recommendation"
                />
              )}
            </EuiFlexItem>
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <ButtonEmpty
                  loading={submitLoading}
                  disabled={submitLoading}
                  onClick={closeTaskFlyout}
                  label="Cancel"
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <Button
                  loading={submitLoading}
                  disabled={submitLoading}
                  fill
                  onClick={submitHandler}
                  type="submit"
                  label="Save"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
        </StyledEuiFlyoutFooter>
      </StyledForm>
    </FormProvider>
  );
};
