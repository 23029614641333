import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useTaskContext } from './context/TaskContext';
import { Check } from './Check';
import { Filters } from './Filters';
import {
  CompletedTable,
  CompletedText,
  ConfettiContainer,
  DismissedLink,
  FiltersText,
  InsightsPageLink,
} from './RecommendationsV2.emotion';
import { TaskTable } from './TaskTable';
import { ThreeConfetti } from './ThreeConfetti';

export interface CompletedTableComponentProps {
  children?: React.ReactNode;
}

export const RecommendationsV2 = () => {
  const { loading, error, filters, dismissedTasks, readyTasksCount } = useTaskContext();
  const [showFilters, setShowFilters] = useState(filters.filtersApplied);
  const history = useHistory();

  useEffect(() => {
    setShowFilters(filters.filtersApplied);
  }, [filters.filtersApplied]);

  const headerText = 'Recommendations to improve your submission';

  const Container = ({ children }) => (
    <>
      <EuiSpacer size="l" />
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3 id="recommendations">{headerText}</h3>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
        {children}
      </EuiPanel>
    </>
  );

  if (error) {
    return <Container>something went wrong please try again</Container>;
  }
  if (loading) {
    return (
      <Container>
        <EuiSpacer size="xl" />
        <LoadingSpinnerV2 />
      </Container>
    );
  }

  const CompletedTableComponent = ({ children }: CompletedTableComponentProps) => (
    <CompletedTable paddingSize="none">
      <EuiFlexGroup direction="column" alignItems="center">
        <ConfettiContainer>
          <ThreeConfetti />
        </ConfettiContainer>
        <Check />
        <EuiText size="m">
          <h2>Nicely Done!</h2>
        </EuiText>
        <CompletedText size="m">
          <p>
            There are no critical recommendations to improve the SOV. Review{' '}
            <InsightsPageLink onClick={() => history.push('/home')}>
              Account Insights
            </InsightsPageLink>
          </p>
        </CompletedText>
        {children}
      </EuiFlexGroup>
    </CompletedTable>
  );

  if (readyTasksCount === 0 && dismissedTasks.length > 0 && !filters.dismissed) {
    return (
      <CompletedTableComponent>
        {' '}
        <FiltersText size="m">
          <p>
            Some recommendations were dismissed, and can still be completed.{' '}
            <DismissedLink
              onClick={() => {
                // this is a string in apps/client/src/precheck/Components/V2/Filters.tsx
                filters.setFilter('dismissed', 'true');
              }}
            >
              Show Dismissed
            </DismissedLink>
          </p>
        </FiltersText>
      </CompletedTableComponent>
    );
  }

  if (readyTasksCount === 0 && dismissedTasks.length === 0) {
    return <CompletedTableComponent />;
  }

  return (
    <>
      <EuiSpacer size="l" />
      <EuiPanel hasShadow={false}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3 id="recommendations">{headerText}</h3>
            </EuiTitle>
          </EuiFlexItem>
          {filters.filtersApplied && (
            <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
              <ButtonEmpty
                onClick={() => {
                  filters.clearFilters();
                }}
                iconName={'x'}
                color="text"
                data-testid="clear-filters"
                label="Clear Filters"
              />
            </EuiFlexItem>
          )}
          <EuiFlexItem style={{ alignSelf: 'end' }} grow={false}>
            <ButtonEmpty
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              color="text"
              data-testid="show-filters"
              iconName={'filter'}
              label={`${showFilters ? 'Hide' : 'Show'} filters`}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />
        {showFilters && (
          <>
            <Filters />
            <EuiSpacer size="l" />
          </>
        )}
        <TaskTable />
      </EuiPanel>
      <EuiSpacer size="xl" />
      <EuiSpacer size="xl" />
    </>
  );
};
