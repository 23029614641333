import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
  Button,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiTitle,
  FilePicker,
} from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useGetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';
import { IGraphQLStream } from '@app/queries/streams/types';
import { formatDate } from '@app/utils/format';
import Currency from '../Currency/Currency';
import { ProcessingBadge, StyledBadge } from '../Portfolios/Portfolios.emotion';

export const SOVSummary = () => {
  const { stream } = useStreamContext();

  const { account } = useAuth();
  const history = useHistory();

  const { selectedOrganization } = useUserSession();
  const organizationName = selectedOrganization?.name;

  const isAdmin = account?.permissions?.admin;

  const hasNonViewManagedOrgs = account?.managedOrgs?.some(
    (o) => o.editDocuments || o.editProjects || o.editProperties || o.editSubmissions,
  );

  const useOrgInQuery = !!(isAdmin || hasNonViewManagedOrgs);

  const { loading, data } = useGetStreamsQuery({
    skip: useOrgInQuery && !organizationName,
    variables: {
      orgName: useOrgInQuery ? organizationName : null,
    },
  });

  const isProcessing = stream?.submissionStatus === 'Processing In Progress';
  const renderBadge = (stream: IGraphQLStream) => {
    if (isProcessing) {
      return <ProcessingBadge>Processing</ProcessingBadge>;
    }
    if (stream.expiryDate && moment.unix(stream.expiryDate).isBefore(moment())) {
      return <StyledBadge>Expired</StyledBadge>;
    }
    return null;
  };

  const formMethods = useForm({ mode: 'onSubmit' });
  const [files, setFiles] = useState([]);

  const updateFile = (file?: File) => {
    if (file) {
      setFiles([file]);
      formMethods.setValue('sovFile', file);
    } else {
      setFiles(undefined);
    }
  };

  if (data?.streamsV2?.streams.length === 0 && !loading) {
    return (
      <EuiPanel paddingSize="l">
        <EuiTitle size="xs">
          <p>SOVs</p>
        </EuiTitle>
        <FormProvider {...formMethods}>
          <FilePicker
            prompt="Upload an SOV"
            data-testid="upload-sov"
            files={files}
            onChange={(files?: File[]) => (files ? updateFile(files[0]) : updateFile(undefined))}
          />
        </FormProvider>
      </EuiPanel>
    );
  }

  const columns = [
    {
      field: 'name',
      name: 'Name',
      render: (name, stream) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={4}>{name}</EuiFlexItem>
          {renderBadge(stream)}
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '40%',
    },
    {
      field: 'totalInsuredValue',
      name: `TIV ${stream?.displayCurrency ? `(${stream?.displayCurrency})` : ''}`,
      render: (tiv, stream) => (
        <EuiText>
          {isProcessing ? null : <Currency value={tiv} currency={stream.displayCurrency} />}
        </EuiText>
      ),
      sortable: true,
      width: '15%',
    },
    {
      field: 'broker',
      name: 'Broker Contact',
      render: (name) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={4}>{name}</EuiFlexItem>
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '15%',
    },
    {
      field: 'propertiesCount',
      name: '# properties',
      render: (propertiesCount) => (isProcessing ? null : <EuiText>{propertiesCount}</EuiText>),
      sortable: true,
      width: '15%',
    },
    {
      field: 'effectiveDate',
      name: 'Effective Date',
      render: (effectiveDate) => <EuiText>{formatDate(effectiveDate)}</EuiText>,
      sortable: true,
      width: '15%',
    },
    {
      field: 'expiryDate',
      name: 'Expiry Date',
      render: (expiryDate) => <EuiText>{formatDate(expiryDate)}</EuiText>,
      sortable: true,
      width: '15%',
    },
  ];

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="xs">
        <p>SOVs</p>
      </EuiTitle>
      <EuiBasicTable
        loading={loading}
        items={data?.streamsV2?.streams || []}
        rowProps={(stream) => ({
          onClick: () => {
            history.push({
              pathname: encodeUrl`/streams/${stream.slug}`,
            });
          },
        })}
        columns={columns}
      ></EuiBasicTable>
      <EuiFlexItem data-testid="recommendations-view-all" grow={false}>
        <Button
          onClick={() => {
            history.push({
              pathname: encodeUrl`/organizations/${selectedOrganization.name}/streams`,
            });
          }}
          size="s"
          label={'View All'}
        />
      </EuiFlexItem>
    </EuiPanel>
  );
};
