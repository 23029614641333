import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { ButtonEmpty, ContextMenu, EuiPageHeader, EuiPanel, EuiSpacer } from 'ui';
import { StringParam, useQueryParam } from 'use-query-params';
import Flyout from '@app/components/Flyout/Flyout';
import { Helmet } from '@app/components/Helmet';
import { PRECHECK_LABEL } from '@app/components/Layout/SideNav/constants';
import NoSovPage from '@app/components/NoSovPage/NoSovPage';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { StreamProvider, useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_PRECHECK_WORKFLOWS_V2 } from '@app/platform/SystemSettings/Flags/types';
import { WorkflowProvider } from '@app/platform/Workflow/context/WorkflowContext';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { DataInsightPanel } from '../Components/DataInsightPanel';
import { Recommendations } from '../Components/Recommendations/Recommendations';
import { TaskProvider } from '../Components/V2/context/TaskContext';
import { DataCompleteness } from '../Components/V2/DataCompleteness';
import { RecommendationsV2 } from '../Components/V2/RecommendationsV2';
import { PrecheckProvider } from '../context/PrecheckContext';

interface StyleProps {
  compact?: boolean;
}

const Container = styled.div<StyleProps>(({ compact }) =>
  compact
    ? `
  margin: 24px auto 24px auto;
  max-width: 1232px;
  width: 75%;
  @media (max-width: 900px) {
    width: 100%;
  }
`
    : '',
);

const DashboardWithoutContext = () => {
  const { selectedOrganization } = useUserSession();
  const { stream, loading } = useStreamContext();
  const location = useLocation();

  if (!selectedOrganization) {
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [referrer, _] = useQueryParam('referrer', StringParam);

  const isPrecheckWorkflowsV2Enabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_PRECHECK_WORKFLOWS_V2,
  );

  const tracker = useTracker();

  useEffect(() => {
    if (stream?.id) {
      tracker.track('Pre-Check: Page Viewed', {
        event_surface: 'PreCheck',
        organization_id: selectedOrganization.id,
        referrer,
        stream_id: stream?.id,
        stream_slug: stream?.slug,
      });
    }
  }, [stream]);

  if (!stream && !loading) {
    return <NoSovPage />;
  }

  const [isPopoverOpen, setPopover] = useState(false);
  const contentRef = useRef();

  const handleExportPDF = () => {
    const currentPath = location.pathname;
    const currentSearch = location.search;
    const newPath = `${currentPath}/export${currentSearch}`;
    window.open(newPath, '_blank');
  };

  return (
    <Flyout>
      <div ref={contentRef}>
        <EuiPanel paddingSize="l" hasBorder={false} hasShadow={false}>
          <Helmet title={PRECHECK_LABEL} />
          <EuiPageHeader
            pageTitle={`SOV ${PRECHECK_LABEL}`}
            rightSideItems={[
              <ContextMenu
                key="export-menu"
                open={isPopoverOpen}
                onClose={() => setPopover(false)}
                panel={{
                  items: [
                    {
                      icon: 'document',
                      label: 'Export to PDF',
                      onClick: handleExportPDF,
                    },
                  ],
                  width: 160,
                }}
                anchorPosition="downRight"
                button={
                  <ButtonEmpty
                    onClick={() => setPopover(!isPopoverOpen)}
                    iconSide="right"
                    iconName="chevronDown"
                    label="Export"
                  />
                }
              ></ContextMenu>,
            ]}
          />
          <EuiSpacer size="m" />
          <Container>
            {isPrecheckWorkflowsV2Enabled ? (
              <TaskProvider withUsers>
                <>
                  <DataCompleteness />
                  <RecommendationsV2 />
                </>
              </TaskProvider>
            ) : (
              <>
                <PrecheckProvider>
                  <WorkflowProvider>
                    <DataInsightPanel />
                    <Recommendations />
                  </WorkflowProvider>
                </PrecheckProvider>
              </>
            )}
          </Container>
        </EuiPanel>
      </div>
    </Flyout>
  );
};

export const PrecheckReport = () => (
  <StreamProvider>
    <DashboardWithoutContext />
  </StreamProvider>
);
