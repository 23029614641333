import React, { useState } from 'react';
import { Asset, EuiSideNav, Icon, useEuiTheme } from 'ui';

import { HeaderLogo } from '../Layout.emotion';
import { useWhiteLabel } from '@app/hooks/useWhiteLabel/useWhiteLabel';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { AdminSettingsDropdown } from '@app/components/AdminSettingsDropdown/AdminSettingsDropdown';

//@ts-ignore
const StyledNav = styled(EuiSideNav)`
  padding: 0 20px 0 8px;

  .euiSideNav__mobileToggle {
    border-bottom: none !important;
  }

  .euiSideNav__content {
    padding: 0 0 0 16px !important;
  }

  .euiSideNavItem {
    margin-top: 0 !important;
    margin-bottom: 16px !important;
  }
`;

const StyledAdminDropdownWrapper = styled.span`
  .euiListGroupItem__button {
    min-height: 18px !important;
    padding: 0 !important;

    svg {
      margin-inline-end: 8px !important;
    }
  }
`;

export const MobileSideNav = ({ items, adminComponent }) => {
  const { account } = useAuth();
  const { euiTheme } = useEuiTheme();
  const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);
  // FIX ME
  // @ts-ignore
  const { assetName, assetLink, logoStyle } = useWhiteLabel(account, false);
  const history = useHistory();

  const toggleOpenOnMobile = () => {
    setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
  };

  const adminItems = adminComponent
    ? [
        {
          label: <StyledAdminDropdownWrapper>{adminComponent}</StyledAdminDropdownWrapper>,
        },
      ]
    : [];

  const Logo = (
    <HeaderLogo {...logoStyle} side="left">
      {assetLink ? (
        <img src={assetLink} />
      ) : (
        <Asset name={assetName} color={euiTheme.colors.primary} />
      )}
    </HeaderLogo>
  );
  return (
    <StyledNav
      mobileTitle={Logo}
      toggleOpenOnMobile={toggleOpenOnMobile}
      mobileBreakpoints={['xs', 's', 'm']}
      isOpenOnMobile={isSideNavOpenOnMobile}
      items={[
        ...items,
        ...adminItems,
        {
          icon: <Icon name="logOut" />,
          id: 'sign-out',
          label: 'Sign Out',
          onClick: () => {
            history.push({ pathname: '/logout' });
          },
        },
      ].map((item) => {
        const menuItem: any = {
          icon: item.icon,
          name: item.label,
          id: item.label,
          css: css`
            .euiSideNavItemButton__icon {
              flex-grow: 0 !important;
            }
          `,
        };

        if (item.href) menuItem.href = item.href;
        if (item.onClick) menuItem.onClick = item.onClick;
        if (item.items) menuItem.items = item.items;
        return menuItem;
      })}
    />
  );
};
