import React from 'react';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { FEATURE_SPOTLIGHT } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { EnterpriseHomePage } from '../EnterpriseHomePage/EnterpriseHomePage';
import { HomePage } from '../HomePage/HomePage';
import { Spotlight } from '../Spotlight/Spotlight';

export const HomePageContainer = () => {
  const { selectedOrganization } = useUserSession();

  const { data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const isEnterpriseAccount = orgInfoData?.orgInfo?.orgInfo?.orgLevel === 'Enterprise';

  const isSpotlightEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_SPOTLIGHT,
  );

  return (
    <StreamProvider>
      {isEnterpriseAccount ? (
        <EnterpriseHomePage />
      ) : isSpotlightEnabled ? (
        <Spotlight />
      ) : (
        <HomePage />
      )}
    </StreamProvider>
  );
};
