import React from 'react';
import {
  Avatar,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  MultiSelect,
  Toggle,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useTaskContext } from './context/TaskContext';
import { StyledBadge } from './TaskTable.emotion';
import './pill.css';

export const Filters = () => {
  const { filters } = useTaskContext();
  const { account } = useAuth();

  const searchUsers = (options): void => {
    filters.setFilter(
      'users',
      options.map((option) => option.value),
    );
  };

  const searchReasons = (options): void => {
    filters.setFilter(
      'reasons',
      options.map((option) => option.value),
    );
  };

  const onSearch = (value: string) => {
    filters.setFilter('name', value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <EuiFlexGroup gutterSize="m" justifyContent="spaceBetween">
      <EuiFlexItem grow={2}>
        <EuiFieldSearch
          data-testid="task-property-search"
          placeholder="Search properties"
          value={filters.name}
          onChange={onChange}
          onSearch={onSearch}
          isClearable
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        <MultiSelect
          key={filters.users.toString()}
          data-testid="task-hazard-filter-select"
          placeholder="All assignees"
          options={filters.userOptions}
          //@ts-ignore
          renderOption={(option, searchValue) => {
            const { label } = option;

            return (
              <EuiFlexGroup gutterSize="s">
                <EuiFlexItem grow={false}>
                  <Avatar name={label} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiHighlight search={searchValue}>{label}</EuiHighlight>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          }}
          onChange={searchUsers}
          initialSelected={filters.userOptions.filter((option) =>
            filters.users.includes(option.value),
          )}
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem grow={4}>
        <MultiSelect
          key={filters.reasons.length}
          data-testid="task-reason-filter"
          //@ts-ignore
          renderOption={(option) => <StyledBadge color={option.color}>{option.label}</StyledBadge>}
          placeholder="All reasons"
          options={filters.reasonOptions}
          onChange={searchReasons}
          initialSelected={filters.reasonOptions.filter((option) =>
            filters.reasons.includes(option.value),
          )}
          fullWidth
        />
      </EuiFlexItem>
      <EuiFlexItem style={{ justifyContent: 'center' }} grow={3}>
        <Toggle
          label="Show dismissed"
          checked={filters.dismissed}
          name="show pe docs toggle"
          onChange={(event) => {
            filters.setFilter('dismissed', event.target.checked.toString());
          }}
        />
      </EuiFlexItem>
      {/* show Assigned to me toggle only if user is admin and page is inbox */}
      {filters.isInboxPage && account.permissions.admin && (
        <EuiFlexItem style={{ justifyContent: 'center' }} grow={3}>
          <Toggle
            label="Assigned to me"
            checked={filters.selfAssigned}
            name="show pe docs toggle"
            onChange={(event) => {
              filters.setFilter('selfAssigned', event.target.checked.toString());
            }}
          />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
