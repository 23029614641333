import React, { createContext, ReactNode, useContext,useState } from 'react';
import { useQuery } from '@apollo/client';
import { keyBy } from 'lodash';
import { useToast } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { SUBMISSION_ATTRIBUTE_METADATA } from './Queries';
import { SubmissionAttributeMetadata } from './types';

interface SubmissionData {
  submissionAttributeMetadata: SubmissionAttributeMetadata[];
}
export interface SubmissionsDashboardContext {
  offset: number;
  setOffset: (input: number) => void;
  filters: any[];
  sortBy: any[];
  pageSize: number;
  searchText: string;
  setSearchText: (input: string) => void;
  statusList: string[];
  statusFilter: string[];
  setStatusFilter: (input: string[]) => void;
  typeList: string[];
  typeFilter: string;
  setTypeFilter: (input: string) => void;
  submissionData: SubmissionData;
  accountOrgTypeFilter: string;
  setAccountOrgTypeFilter: (input: string) => void;
  accountOrgTypeList: string[];
}

export const SubmissionsDashboardContext = createContext({} as SubmissionsDashboardContext);
export const SubmissionsDashboardProvider = ({ children }: { children: ReactNode }) => {
  const toast = useToast();
  const [searchText, setSearchText] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string>('0');
  const [accountOrgTypeFilter, setAccountOrgTypeFilter] = useState<string>('0');
  const [offset, setOffset] = useState<number>(0);
  const sortBy: any[] = [];
  const filters = [
    searchText?.length > 0 && {
      attributes: ['name', 'broker', 'underwriter', 'underwriterTechnician', 'team', 'region'],
      operator: 'CONTAINS',
      values: [searchText],
    },
    statusFilter.length > 0 && {
      attributes: ['accountStatus'],
      operator: 'EQUAL',
      values: statusFilter,
    },
    typeFilter !== '0' && {
      attributes: ['submissionType'],
      operator: 'EQUAL',
      values: [typeFilter],
    },
    accountOrgTypeFilter !== '0' && {
      attributes: ['accountOrgType'],
      operator: 'EQUAL',
      values: [accountOrgTypeFilter],
    },
  ].filter(Boolean);

  const pageSize = 10000;

  const { selectedOrganization } = useUserSession();

  const { data: submissionData } = useQuery<{
    submissionAttributeMetadata: SubmissionAttributeMetadata[];
  }>(SUBMISSION_ATTRIBUTE_METADATA, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: { input: { orgName: selectedOrganization?.name } },
  });

  const submissionAttributeMetadata: SubmissionAttributeMetadata[] =
    submissionData?.submissionAttributeMetadata;

  const metadataMap = keyBy(submissionAttributeMetadata, 'name');

  const statusList = metadataMap.accountStatus?.enumValues?.map(({ displayName }) => displayName);

  const typeList = ['New', 'Renewal'];
  const accountOrgTypeList = ['Client', 'Prospect'];

  return (
    <SubmissionsDashboardContext.Provider
      value={{
        accountOrgTypeFilter,
        accountOrgTypeList,
        filters,
        offset,
        pageSize,
        searchText,
        setAccountOrgTypeFilter,
        setOffset,
        setSearchText,
        setStatusFilter,
        setTypeFilter,
        sortBy,
        statusFilter,
        statusList,
        submissionData,
        typeFilter,
        typeList,
      }}
    >
      {children}
    </SubmissionsDashboardContext.Provider>
  );
};

export const useSubmissionsDashboard = () => {
  const context = useContext(SubmissionsDashboardContext);
  return context;
};
