import React, { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiProgress, EuiSpacer, EuiText, useEuiTheme } from 'ui';
import ChartTitle from '@app/cx/Reports/Charts/ChartTitle/ChartTitle';
import { formatStreamCurrency } from '@app/cx/Stream/utils';

interface IProps {
  bars: any;
  title: string;
}

const Swatch = ({ color }) => (
  <div style={{ backgroundColor: color, height: 9, marginRight: 5, width: 9 }} />
);

const ProgressDisplay = ({ attribute }) => {
  const { euiTheme } = useEuiTheme();
  const completeTivAttribute = attribute.scores?.Complete?.tiv || 0;

  return (
    <div>
      <EuiProgress
        label={<ChartTitle titleSize="medium">{attribute.label}</ChartTitle>}
        valueText={`${Math.round(attribute.scores?.Complete?.tivPct || 0)}% (${formatStreamCurrency(
          completeTivAttribute,
        )}) TIV`}
        max={100}
        value={Math.round(attribute.scores?.Complete?.tivPct || 0)}
        color={euiTheme.colors.primary}
        size="s"
      />
      <EuiSpacer size="l" />
    </div>
  );
};

export const AttributeInsightDisplay: FC<IProps> = ({ bars, title }) => {
  const { euiTheme } = useEuiTheme();
  if (bars.length === 0) {
    return <>No data</>;
  }

  const hazardSection = ['Earthquake', 'Wind', 'Flood', 'Severe Convective Storm'].includes(title);
  const geoSection = title === 'geocode';

  const completeTiv = bars[0].scores?.Complete?.tiv || 0;
  const incompleteTiv = bars[0].scores?.Incomplete?.tiv || 0;
  const totalTiv = formatStreamCurrency(completeTiv + incompleteTiv);

  const incompleteLocations = bars[0].scores?.Incomplete?.locations || 0;
  const completeLocations = bars[0].scores?.Complete?.locations || 0;
  const totalLocations = incompleteLocations + completeLocations;

  const firstSection = Math.ceil(bars.length / 2);

  let chartTitle = `Attribute completeness of ${totalLocations} properties (${totalTiv} tiv) `;
  if (hazardSection) {
    chartTitle = `Attribute completeness of ${totalLocations} (${totalTiv} tiv) ${title.toLocaleLowerCase()}
    exposed properties`;
  }
  if (geoSection) {
    chartTitle = `Geocoding data of ${totalLocations} properties (${totalTiv} tiv)`;
  }

  return (
    <>
      <ChartTitle>{chartTitle}</ChartTitle>
      <EuiSpacer size="l" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <div style={{ marginBottom: '24px' }}>
            <EuiFlexGroup style={{ flexWrap: 'nowrap' }}>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup gutterSize="none" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <Swatch color={euiTheme.colors.primary} />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size="xs">
                      <small>
                        % of TIV with{' '}
                        {geoSection ? 'with roof centroid geocoding' : 'complete data'}
                      </small>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup gutterSize="none" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <Swatch color="lightgrey" />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size="xs">
                      <small>
                        % of TIV{' '}
                        {geoSection ? 'without roof centroid geocoding' : 'with incomplete data'}
                      </small>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup style={{ gap: '48px' }}>
        <EuiFlexItem grow={3}>
          {bars.slice(0, firstSection).map((attribute, index) => (
            <ProgressDisplay key={index} attribute={attribute} />
          ))}
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          {bars.slice(firstSection, bars.length).map((attribute, index) => (
            <ProgressDisplay key={index} attribute={attribute} />
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
