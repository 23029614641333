import React from 'react';
import { useLocation } from 'react-router';
import { EuiHeaderSection } from 'ui';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { OrgSwitcherButton } from '@app/cx/OrgSwitcher/OrgSwitcherButton';
import { OrgSwitcherFlyout } from '@app/cx/OrgSwitcher/OrgSwitcherFlyout/OrgSwitcherFlyout';
import { pathMatches } from '@app/utils/url';
import { Alert } from '../Alert';
import { GlobalHeader } from '../Layout.emotion';
import { HelpButton } from './HelpButton/HelpButton';
import { OnboardAccountButton } from './OnboardAccountButton';
import { RightHeaderItem } from './TopNav.emotion';

const showHelpButton = (pathname: string): boolean =>
  (pathMatches(pathname, '/organizations/*/properties*') ||
    pathMatches(pathname, '/streams/*')) &&
  !pathMatches(pathname, '/organizations/*/streams') &&
  !pathMatches(pathname, '/streams/*/domains') &&
  !pathMatches(pathname, '/streams/*/viewers');

interface IProps {
  hideAlert: () => void;
  message: string;
  shouldShowAlert: boolean;
  enterpriseOrgName?: string;
}

export const TopNav: React.FC<IProps> = ({
  hideAlert,
  message,
  shouldShowAlert,
  enterpriseOrgName,
}) => {
  const { showFlyout } = useFlyout();
  const location = useLocation();

  return (
    <>
      <Alert
        enterpriseOrgName={enterpriseOrgName}
        hideAlert={hideAlert}
        message={message}
        shouldShowAlert={shouldShowAlert}
      />
      <GlobalHeader position="fixed">
        <EuiHeaderSection side="left">
          <OrgSwitcherButton
            isLarge
            onClick={() => showFlyout(<OrgSwitcherFlyout side="left" />)}
          />
        </EuiHeaderSection>
        <EuiHeaderSection style={{ alignItems: 'center' }} side="right">
          <OnboardAccountButton />
          {showHelpButton(location.pathname) && (
            <RightHeaderItem border="none">
              <HelpButton />
            </RightHeaderItem>
          )}
        </EuiHeaderSection>
      </GlobalHeader>
    </>
  );
};
