import { IGraphQLStream } from '@app/queries/streams/types';

export enum ESubmissionType {
  New = 'New',
  Renewal = 'Renewal',
}

export enum AccountOrgType {
  Client = 'Client',
  Prospect = 'Prospect',
}

export interface UserOption {
  label: string;
  value: string;
}

export interface FormValues extends Omit<SubmissionForm, 'underwriter' | 'underwriterTechnician'> {
  underwriter: UserOption[];
  underwriterTechnician: UserOption[];
}

export interface SubmissionForm {
  name: string;
  broker: string;
  underwriter: string;
  underwriterEmail: string;
  underwriterTechnician: string;
  underwriterTechnicianEmail: string;
  submissionType: ESubmissionType;
  accountOrgType: AccountOrgType;
  accountStatus: string | null;
  effectiveDate: any;
  expiryDate: any;
  quoteDueDate: any;
  gusl: string;
  industry: string;
  businessType: string;
  comment: string;
  assumptions: string;
  internalNotes?: string;
  sla?: number;
  region?: string;
  team?: string;
  teamMemberEmails: Array<string>;
  clientId?: string;
  brokerEmail?: string;
  adjustedSLA?: number;
  reasonForAdjustment?: string;
  uwFeedbackRequired?: boolean;
  qaIssuesExternalCount?: number;
  customerStreamSlug?: string;
  documents?: Array<File>;
  archipelagoTransformations?: boolean;
}
export interface SubmissionInput
  extends Omit<
    SubmissionForm,
    'effectiveDate' | 'expiryDate' | 'quoteDueDate' | 'customerStreamSlug' | 'documents'
  > {
  effectiveDate: string;
  expiryDate: string;
  quoteDueDate: string;
}
interface ICreateSubmissionInput {
  carrierOrgName: string;
  documents: Array<File>;
  submission: SubmissionForm;
  customerStreamSlug?: string;
}
export interface ICreateSubmissionVariables {
  input: ICreateSubmissionInput;
}

export interface ISubmission {
  id: string;
  organizationId: string;
  orgName: string;
  totalInsuredValue: number;
  name?: string;
  submissionType?: ESubmissionType;
  accountStatus?: string;
  underwriter?: string;
  broker?: string;
  quoteDueDate: string;
  expiryDate: string;
  effectiveDate: string;
  propertiesCount: number;
  gusl?: string;
  industry?: string;
  businessType?: string;
  region?: string;
  team?: string;
  clientId?: string;
  submitterEmail?: string;
  brokerEmail?: string;
  comment: string;
  assumptions: string;
  stream: IGraphQLStream;
  sla?: number;
  qaIssuesExternalCount?: number;
  internalNotes?: string;
}
export interface IGetSubmissionsVariables {
  input: {
    orgName: string | null;
    offset: number;
    filters?: any[];
    sortBy?: any[];
    pageSize: number;
    latestOnly: boolean;
  };
}

export interface IGetSubmissionsData {
  submissionsPageV2: {
    pageInfo: { hasNextPage: boolean; cursor: string; totalCount: number };
    submissions: Array<ISubmission>;
  };
}

export interface DeleteSubmissionVars {
  input: {
    id: string;
  };
}

export interface UpdateSubmissionStreamVars {
  input: {
    id: string;
    customerStreamSlug: string;
  };
}

export interface IGetStreamVars {
  slug: string;
  userCode: string | undefined;
  isAdmin: boolean;
}

export interface IGetStreamData {
  streamV2: {
    stream: {
      id: string;
      name: string;
      slug: string;
      orgName: string;
    };
  };
}

interface SubmissionEnumValue {
  value: string;
  displayName: string;
  enabled: boolean;
}
export interface SubmissionAttributeMetadata {
  type: string;
  name: string;
  displayName: string;
  enabled: boolean;
  required: boolean;
  enumEditable?: boolean;
  enumValues?: Array<SubmissionEnumValue>;
  group?: string | null;
}

export interface Submission {
  teamMemberEmails: string[];
  id: string;
  accountStatus: string;
  accountOrgType: AccountOrgType;
  adjustedSLA: number;
  assumptions: string;
  internalNotes: string;
  broker: string;
  businessType: string;
  comment: string;
  effectiveDate: string;
  expiryDate: string;
  gusl: string;
  industry: string;
  name: string;
  qaIssuesExternalCount: number;
  quoteDueDate: string;
  reasonForAdjustment: string;
  sla: string;
  submissionType: string;
  underwriterEmail: string;
  underwriterTechnicianEmail: string;
  uwFeedbackRequired: boolean;
  region: string;
  team: string;
  clientId: string;
  brokerEmail: string;
}

export type RegionTeamsObject = {
  name: string;
  teams: string[];
};
