import styled from '@emotion/styled';
import { EuiBadge, EuiBasicTable, EuiText } from 'ui';

export const StyledResultsText = styled(EuiText)`
  padding-top: 8px;
`;

export const StyledBoundTable = styled(EuiBasicTable as any)`
  tr.expired {
    background-color: #f7f8fc;
  }
`;

export const StyledTablesDiv = styled.div`
  padding: 32px;
  background-color: #fafbfd;
`;

export const StyledBadge = styled(EuiBadge)`
  color: #fff;
  margin: 3px;
  background-color: #69707d;
`;

export const ProcessingBadge = styled(EuiBadge)`
  color: #fff;
  margin: 3px;
  background-color: #0077cc;
`;
