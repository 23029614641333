import * as Types from '../../../precheck.types';

import { gql } from '@apollo/client';
import { TaskWithoutFullPropertyFragmentDoc } from './TaskFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTasksQueryVariables = Types.Exact<{
  input: Types.GetTasksInput;
}>;


export type GetTasksQuery = { __typename?: 'Query', getTasks?: { __typename?: 'TaskResponse', tasks: Array<{ __typename?: 'Task', id: string, status?: Types.TaskStatus | null, assignees?: Array<{ __typename?: 'TaskAssignee', assignedDate?: any | null, assignee?: string | null, userInfo?: { __typename?: 'UserProfile', email: string, givenName?: string | null, familyName?: string | null } | null } | null> | null, propertyRecommendation: { __typename?: 'Recommendation', archipelagoID: string, bucket?: number | null, valuationOutlierReason?: Types.ValuationOutlierReason | null, attribute?: Array<{ __typename?: 'Attribute', name: string, recommendationType: Types.RecommendationType, documents?: Array<string | null> | null, reasons?: Array<string | null> | null }> | null }, property: { __typename?: 'Property', city?: string | null, id: string, locationName?: string | null, postalCode?: string | null, state?: string | null, streetAddress?: string | null, totalInsuredValueDisplay?: number | null } }> } | null };


export const GetTasksDocument = gql`
    query GetTasks($input: GetTasksInput!) {
  getTasks(input: $input) {
    tasks {
      ...taskWithoutFullProperty
    }
  }
}
    ${TaskWithoutFullPropertyFragmentDoc}`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;