import React, { useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import AddStreamModal from '@app/cx/Stream/AddModal/AddStreamModal';
import { HeaderTitle } from '@app/dice/JobDetails/JobDetails.emotion';
import { StyledButton, StyledFlexItem, StyledProcessingPage } from './NoSovPage.emotion';

const NoSovPage: React.FC = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  return (
    <StyledProcessingPage>
      <EuiFlexGroup alignItems="center" direction="column">
        <EuiFlexItem grow={false}>
          <EuiText size="m" data-testid="job-details-title">
            <HeaderTitle>Upload an SOV to get started</HeaderTitle>
          </EuiText>
        </EuiFlexItem>
        <StyledFlexItem grow={false}>
          <EuiText>Get better SOV data and prioritized recommendations for improvement.</EuiText>
        </StyledFlexItem>
        <StyledFlexItem grow={false}>
          <StyledButton
            onClick={() => setIsModalShown(true)}
            label="Upload SOV"
            fill
            size="l"
            style={{ marginBottom: '6px' }}
          />
          <EuiText textAlign="center">
            <a href="/support">Questions? Send us a support ticket</a>
          </EuiText>
        </StyledFlexItem>
      </EuiFlexGroup>
      {isModalShown && (
        <AddStreamModal hasMyPropertiesStream={false} onCloseModal={() => setIsModalShown(false)} />
      )}
    </StyledProcessingPage>
  );
};

export default NoSovPage;
