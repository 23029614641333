import React, { useContext, useState } from 'react';
import { EuiComboBox, EuiFormRow } from 'ui';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useGetAccountUsersQuery } from '@app/graphql/queries/rbac/__generated__/AccountUsers.generated';
import { getFormLabel } from './FormFields';
import { FieldMeta } from './validator';

interface Props {
  label: string;
  disabled?: boolean;
  onCreateOption: (searchValue: string) => void;
  setSelectedOptions: (v: Array<string>) => void;
  selectedValues: Array<string>;
  meta?: FieldMeta;
  required?: boolean;
  touch: () => void;
}

export const TeamMembersDropdown: React.FC<Props> = ({
  label,
  required,
  meta,
  selectedValues,
  setSelectedOptions,
  touch,
}) => {
  const { selectedOrganization } = useContext(UserSessionContext);

  const { data } = useGetAccountUsersQuery({
    variables: {
      input: {
        limit: 1500,
        offset: 0,
        orgName: selectedOrganization.name,
      },
    },
  });

  const accountUsers = data?.accountUsers || [];

  const initialSelectedOptions = selectedValues?.map((email) => ({
    label: email,
    value: email,
  }));

  const allOptions = accountUsers?.map((u) => ({
    label: u.profile.email,
    value: u.profile.email,
  }));

  const [options, setOptions] = useState(initialSelectedOptions);

  const onChange = (selected) => {
    setOptions(selected);
    setSelectedOptions(selected?.map((o) => o.value));
  };

  const onCreateOption = (searchValue, flattenedOptions = []) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue,
      value: searchValue,
    };

    // Create the option if it doesn't exist.
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue,
      ) === -1
    ) {
      setOptions([...options, newOption]);
    }

    // Select the option.
    setSelectedOptions([...(options || []), newOption].map((o) => o.value));
  };

  const { error, touched } = meta || {};
  const isInvalid = !!error && !!touched;

  return (
    <EuiFormRow label={getFormLabel(label, required)} isInvalid={isInvalid} error={error}>
      <EuiComboBox
        data-testid={`submission-dropdown-${label.split(' ').join('-')}`}
        options={allOptions}
        selectedOptions={options}
        onChange={onChange}
        onCreateOption={onCreateOption}
        isClearable
        //@ts-ignore
        invalid={isInvalid}
        onBlur={touch}
      />
    </EuiFormRow>
  );
};
