import React from 'react';
import { useHistory } from 'react-router';
import { Button, EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTitle } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { colorMap } from '@app/precheck/Components/V2/constants/colorMap';
import { labelMap } from '@app/precheck/Components/V2/constants/labelMap';
import { useTaskContext } from '@app/precheck/Components/V2/context/TaskContext';
import { StyledBadge } from '@app/precheck/Components/V2/TaskTable.emotion';
import EmptyMessage from '../EmptyMessage/EmptyMessage';

export const RecommendationSummary = () => {
  const { tasks, loading } = useTaskContext();
  const history = useHistory();

  const { selectedOrganization } = useUserSession();

  if (tasks?.length === 0 && !loading) {
    return (
      <EuiPanel paddingSize="l">
        <EuiTitle size="xs">
          <p>Recommendations</p>
        </EuiTitle>
        <EmptyMessage subText="There are no critical recommendations to improve the SOV" />;
      </EuiPanel>
    );
  }

  const columns = [
    {
      name: 'Property',
      render: (task) => (
        <EuiFlexGroup gutterSize="none" direction="column">
          <EuiFlexItem style={{ fontWeight: 600 }} grow={false}>
            {task.propertyName}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{task.propertyCityAndState}</EuiFlexItem>
        </EuiFlexGroup>
      ),
      width: '25%',
    },
    {
      field: 'reasons',
      name: 'Reason for recommendation',
      render: (reasons) => (
        <EuiFlexGroup wrap gutterSize="xs">
          {reasons.map((reason, index) => (
            <EuiFlexItem key={index} grow={false}>
              <StyledBadge color={colorMap[reason]}>{labelMap[reason]}</StyledBadge>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      ),
      width: '21%',
    },
  ];

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="xs">
        <p>Recommendations</p>
      </EuiTitle>
      <EuiBasicTable
        key={tasks?.length}
        loading={loading}
        items={tasks || []}
        rowProps={(task) => ({
          onClick: () => {
            history.push({
              pathname: encodeUrl`/organizations/${selectedOrganization.name}/precheck/&?taskId=${task.id}&`,
            });
          },
        })}
        columns={columns}
      ></EuiBasicTable>
      <EuiFlexItem data-testid="recommendations-view-all" grow={false}>
        <Button
          onClick={() => {
            history.push({
              pathname: encodeUrl`/organizations/${selectedOrganization.name}/precheck/`,
            });
          }}
          size="s"
          label={'View All'}
        />
      </EuiFlexItem>
    </EuiPanel>
  );
};
