import React, { useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Button,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import {
  OrganizationActivitiesQuery,
  useOrganizationActivitiesQuery,
} from '@app/graphql/queries/organizationActivities/__generated__/organizationActivities.generated';
import { useGetStreamQuery } from '@app/graphql/queries/streams/__generated__/StreamV2.generated';
import { ActivityEvent } from '@app/graphql/types';
import EmptyMessage from '../EmptyMessage/EmptyMessage';

export const ActivitySummary = () => {
  const { selectedOrganization } = useUserSession();

  const { account } = useAuth();

  const { stream } = useStreamContext();

  const isAdmin = account?.permissions?.admin || false;

  const [items, setItems] = useState<
    OrganizationActivitiesQuery['organizationActivities']['activities']
  >([]);

  const { data, loading, refetch, networkStatus } = useOrganizationActivitiesQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setItems((prevState) => [...prevState, ...(data?.organizationActivities?.activities || [])]);
    },
    variables: {
      input: {
        cursor: null,
        limit: 3,
        organizationName: selectedOrganization.name,
      },
    },
  });

  const { data: streamV2 } = useGetStreamQuery({
    skip: !stream,
    variables: {
      isAdmin,
      slug: stream?.slug || '',
      userCode: account?.userCode || '',
    },
  });

  const isLoading =
    loading || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore;

  const loadMore = async () => {
    await refetch({
      input: {
        cursor: data?.organizationActivities?.cursor,
        limit: 10,
        organizationName: selectedOrganization.name,
      },
    });
  };

  const columns: Array<EuiBasicTableColumn<ActivityEvent>> = [
    {
      field: 'eventDetails',
      name: '',
      render: (eventDetails: string, eventInfo) => {
        const attributes = JSON.parse(eventDetails)?.attributes || {};
        const attributesEdited = Object.values(attributes).length;
        const property = eventInfo.properties[0];
        return (
          <EuiFlexGroup direction="column" justifyContent="flexStart" gutterSize="none">
            <EuiFlexItem grow={false}>
              <b>
                {attributesEdited > 1
                  ? attributesEdited + ' attributes edited'
                  : `${streamV2?.propertyAttributeMetadataV2?.find((meta) => meta.name === attributes[0])?.displayName} edited`}
              </b>
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
            >{`${property.streetAddress}, ${property.city}, ${property.state}`}</EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
    {
      field: 'email',
      name: '',
      render: (email: string, eventInfo) => {
        const date = new Date(eventInfo.timestamp);

        const formattedDate = date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });

        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          hour12: true,
          minute: '2-digit',
        });

        return (
          <EuiFlexGroup direction="column" justifyContent="flexEnd" gutterSize="none">
            <EuiFlexItem>{email}</EuiFlexItem>
            <EuiFlexItem grow={false}>{`${formattedDate} @ ${formattedTime}`}</EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
  ];

  if (items.length === 0 && !isLoading) {
    return (
      <EuiPanel paddingSize="l">
        <EuiTitle size="xs">
          <p>Recent Activity</p>
        </EuiTitle>
        <EmptyMessage subText="There are no activity log(s) to show. Please come back later." />
      </EuiPanel>
    );
  }

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="xs">
        <p>Recent Activity</p>
      </EuiTitle>
      <EuiBasicTable
        style={{ overflowY: 'auto' }}
        columns={columns}
        loading={isLoading}
        // @ts-ignore
        items={items}
      />
      <EuiFlexItem data-testid="renew-sov-btn" grow={false}>
        <Button
          onClick={() => {
            loadMore();
          }}
          size="s"
          label={'View More'}
        />
      </EuiFlexItem>
    </EuiPanel>
  );
};
