import {
  EuiFormControlLayout
} from '@elastic/eui';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from '@emotion/styled';

const StyledCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  height: 38px;
  padding: 12px;
  background-color: #f9fbfd;
  border: none;
  color: #343741;
  border-radius: 6px 0 0 6px;
  font-size: 14px;
  background-size: 0% 100%;
  background-repeat: no-repeat;
  transition: box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in;

  &:focus {
    border-color: #4a90e2;
    background-color: #fff;
    background-image: linear-gradient(to top, #0f766e, #0f766e 2px, transparent 2px, transparent 100%);
    background-size: 100% 100%;
    outline: none;
  }
  
  &.with-border {
    box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(22, 61, 76, 0.1);
    border-radius: 6px;
  }
`;

const StyledAppendDiv = styled.div`
  padding: 12px;
  line-height: 16px !important;
  font-size: 1rem;
`;

const InputWithFormattedValue = (props) => {
  const { append = null, name, value, onChange, intlConfig = undefined  } = props;

  return (
    <EuiFormControlLayout
      append={append && <StyledAppendDiv>{append}</StyledAppendDiv>}
    >
      <StyledCurrencyInput
        id={name}
        name={name}
        defaultValue={value}
        decimalsLimit={2}
        allowDecimals
        className={(!append && 'with-border') || ''}
        onValueChange={(value, name, values) => onChange(values.float)}  
        intlConfig={intlConfig}
      />
    </EuiFormControlLayout>
  )
}

export default InputWithFormattedValue;
