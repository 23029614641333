import React from 'react';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useAnalyticsConfigQuery } from '@app/graphql/queries/analyticsConfig/__generated__/analyticsConfig.generated';
import { AnalyticsType } from '@app/graphql/types';
import GoodDataReactSDKIntegration from '../GoodDataReactSDKIntegration/GoodDataReactSDKIntegration';

export const DashboardV2 = () => {
  const { selectedOrganization } = useUserSession();

  const { data } = useAnalyticsConfigQuery({
    variables: {
      input: {
        analyticsType: AnalyticsType.Spotlight,
        orgName: selectedOrganization?.name,
      },
    },
  });

  if (!data) {
    return null;
  }

  const signedJWT = data?.analyticsConfig?.signedJWT;
  const enterpriseReportingHostURL = data?.analyticsConfig?.enterpriseReportingHostURL;
  const analyticsConfig = data?.analyticsConfig?.analyticsConfig;
  const workspaceID = analyticsConfig?.workspaceID;
  const dashboard = analyticsConfig?.dashboards[0];
  const filterID = analyticsConfig?.dashboards[0].filters[0]?.id;
  const dashboardID = dashboard?.id;

  return (
    <GoodDataReactSDKIntegration
      apiToken={signedJWT}
      workspaceID={workspaceID}
      hostname={enterpriseReportingHostURL}
      dashboardID={dashboardID}
      filterID={filterID}
      orgID={selectedOrganization?.id}
    />
  );
};
