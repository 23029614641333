import React from 'react';
import { EuiPageHeader, EuiPanel, EuiSpacer } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { TaskProvider } from '@app/precheck/Components/V2/context/TaskContext';
import { ActivitySummary } from '../ActivitySummary/ActivitySummary';
import { RecommendationSummary } from '../RecommendationSummary/RecommendationSummary';
import { SOVSummary } from '../SOVSummary/SOVSummary';
import { SupportSummary } from '../SupportSummary/SupportSummary';
import { HomeContainer } from './Home.emotion';

export const Home = () => {
  const { selectedOrganization } = useUserSession();
  const { account } = useAuth();
  const permissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties = permissions?.includes('canManageProperties');

  return (
    <EuiPanel paddingSize="l">
      <EuiPageHeader pageTitle="Action Items"></EuiPageHeader>
      <EuiSpacer size="l" />
      <HomeContainer>
        <SOVSummary />
        <TaskProvider withUsers>
          <RecommendationSummary />
        </TaskProvider>
        <SupportSummary />
        {canManageProperties && (
          <StreamProvider>
            <ActivitySummary />
          </StreamProvider>
        )}
      </HomeContainer>
    </EuiPanel>
  );
};
