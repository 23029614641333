import React from 'react';
import { Link } from 'react-router-dom';
import { EuiFlyoutHeader, EuiSpacer, EuiText, EuiTextColor, EuiTitle, useEuiTheme } from 'ui';
import { StreamProvider, useStreamContext } from '@app/cx/Stream/StreamProvider';
import { Assignment } from './Assignment';
import { Documents } from './Documents';
import styled from '@emotion/styled';

const StyledFlyout = styled(EuiFlyoutHeader)`
  padding: 24px !important;
`;

const FlyoutHeaderWithStreamSlug = ({ task, streamSlug, hasBorder }) => {
  const { euiTheme } = useEuiTheme();

  return (
    <StyledFlyout hasBorder={hasBorder}>
      <EuiTitle size="s">
        <EuiText>
          Complete Recommendations for{' '}
          <EuiTextColor color={euiTheme.colors.primary}>
            <Link
              style={{ fontWeight: 600 }}
              to={{
                pathname: `/streams/${streamSlug}/property/${task.propertyUid}`,
              }}
            >
              {task.propertyName}
            </Link>
          </EuiTextColor>
        </EuiText>
      </EuiTitle>
      <EuiSpacer size="m" />
      <Assignment tasks={[task]} />
      <Documents documents={task.propertyDocuments} />
    </StyledFlyout>
  );
};

const FlyoutWithContext = ({ task, hasBorder }) => {
  const { stream } = useStreamContext();

  return <FlyoutHeaderWithStreamSlug hasBorder={hasBorder} task={task} streamSlug={stream?.slug} />;
};

export const FlyoutHeader = ({ task, streamSlug, hasBorder }) => {
  if (!streamSlug) {
    return (
      <StreamProvider>
        <FlyoutWithContext hasBorder={hasBorder} task={task} />
      </StreamProvider>
    );
  }

  return <FlyoutHeaderWithStreamSlug hasBorder={hasBorder} task={task} streamSlug={streamSlug} />;
};
